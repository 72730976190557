import("./bootstrap");

// If landing page is running as a standalone app,
// we apply an observer to fix an issue between
// the scrollbar handling introduced in the index.css and MUI modals.
// See the related MUI issue here: https://github.com/mui/material-ui/issues/34513
let originalStyle = document.documentElement.style.overflow;
const observer = new MutationObserver(function (mutations) {
  mutations.forEach(function (mutationRecord) {
    // This observer will add "overflow: hidden" to the html
    // element when it is added to the body and restores the original
    // value when the "overflow: hidden" on the body is removed
    if (document.body.style.overflow === "hidden") {
      originalStyle = document.documentElement.style.overflow;
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = originalStyle;
    }
  });
});
observer.observe(document.body, {
  attributes: true,
  attributeFilter: ["style"],
});

export {};
